import { Icon } from "leaflet";
import markerIconPng from "leaflet/dist/images/marker-icon.png";
import "leaflet/dist/leaflet.css";
import React, { useEffect, useState } from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import "./App.css";
import About from "./about.svg";
import getData from "./getData";

function App() {
  const [datax, setDatax] = useState([]);
  const [showResults, setShowResults] = React.useState(true);
  const showText = () => setShowResults(false);
  const hideText = () => setShowResults(true);
  const data = getData();

  useEffect(() => {
    console.log("Mounted");
    data.then((data) => {
      console.log(data.data);
      setDatax(data.data);
    });
  }, []);
  const outerBounds = [
    [48.3232456, 16.082092],
    [48.0774487, 16.7057789],
  ];

  return (
    <div className="App">
      <MapContainer
        center={[48.20849, 16.37208]}
        zoom={13}
        minZoom={12}
        scrollWheelZoom={true}
        zoomSnap={0.5}
        maxBounds={outerBounds}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        />
        {datax.map((row) => (
          <Marker
            key={row[0]}
            position={[row[4], row[5]]}
            icon={
              new Icon({
                iconUrl: markerIconPng,
                iconSize: [25, 41],
                iconAnchor: [12, 41],
              })
            }
          >
            <Popup>
              Message_id: {row[1]} <br />
              Date: {row[2]} <br />
              Time: {row[3]} <br />
            </Popup>
          </Marker>
        ))}
      </MapContainer>
      <div id="about">
        <img
          src={About}
          className="aboutImage"
          onClick={showResults ? showText : hideText}
        />
      </div>
      <div className="aboutContent" hidden={showResults} onClick={hideText}>
        <div className="aboutText">
          This page shows all documented bikecop coordinates posted on this
          telegram channel
          <br></br>
          <a href="https://t.me/RadkontrollenWien">Radkontrollen: Wien</a>
          <br></br>
          Release date: 20.12.2022
        </div>
      </div>
    </div>
  );
}

export default App;
