import React from 'react';
import Papa from 'papaparse';

export default async function getData() {
  const data = Papa.parse(await fetchCsv());
//   console.log('data', data);
  return data;
}

async function fetchCsv() {
  const response = await fetch('new_dataset.csv');
  const reader = response.body.getReader();
  const result = await reader.read();
  const decoder = new TextDecoder('utf-8');
  const csv = await decoder.decode(result.value);
//   console.log('csv', csv);
  return csv;
}